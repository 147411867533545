import { Agency, AgencyGroup } from "@socotec.io/socio-vue-components";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { utils } from "@socotec.io/socio-vue-components";
import User from "@/models/User";
import google_protobuf_empty_pb from "google-protobuf/google/protobuf/empty_pb.js";
import { arrayNonPresentKeys } from "@/utils/helpers";
import { cloneDeep } from "lodash";
import { useRxdbCollection } from "@/composables/useRxdbCollection";

const state = {
  userCompany: null,
};

const getters = {
  getCurrentUser: () => {
    return User.query()
      .where("isCurrentUser", true)
      .with("agencyDatas.agencyGroupDatas")
      .first();
  },
  getUserByUserManagementUuid: () => (usermanagementUuid) => {
    return User.query().where("usermanagementUuid", usermanagementUuid).first();
  },
  getUserCompany: (state) => {
    return state.userCompany;
  },
};

const amosClient = socioGrpcClient.amos_back.user;
const userClient = socioGrpcClient.user_management.user;

const actions = {
  async initializeCurrentUser({ commit }) {
    const userResponse =
      await amosClient.UserControllerPromiseClient.resolveUser(
        new google_protobuf_empty_pb.Empty(),
        {}
      );

    const userData = userResponse.toObject();
    const agency = userData.agency;
    try {
      if (agency?.uuid) {
        if (agency.agencyGroup?.uuid) {
          await AgencyGroup.insert({
            data: agency.agencyGroup,
          });
        }
        // Only store agencyGroup relation or empty string
        agency.agencyGroup = agency.agencyGroup?.uuid || "";
        await Agency.insert({
          data: agency,
        });
      }
    } catch (error) {
      console.log(userData);
      console.error(error);
    }
    commit("SET_USER_COMPANY", userData.company);
    const keysMap = {
      groupsList: "groups",
      managedProjectsList: "managedProjects",
      rolesList: "roles",
    };

    const userDataForRxdb = cloneDeep(userData);
    delete userDataForRxdb.username;
    delete userDataForRxdb.dateJoined;
    delete userDataForRxdb.createdAt;
    delete userDataForRxdb.updatedAt;
    delete userDataForRxdb.lastSsoSync;
    delete userDataForRxdb.company;
    delete userDataForRxdb.agency;
    delete userDataForRxdb.rolesList;
    const { execOnCollection: userCollection } = useRxdbCollection("user");
    await userCollection((c) =>
      c.upsert({
        ...utils.grpc.renameKeys(keysMap, userDataForRxdb),
        isCurrentUser: true,
        agency: agency.uuid,
      })
    );
    return await User.insert({
      data: {
        ...utils.grpc.renameKeys(keysMap, userData),
        isCurrentUser: true,
        agency: agency.uuid,
      },
    });
  },
  async partialUpdateUser(_, { userData, fields }) {
    await User.update({
      where: userData.uuid,
      data: userData,
    });

    const request = socioGrpcClient.javascriptToRequest(
      amosClient.UserPartialUpdateRequest,
      userData,
      arrayNonPresentKeys(userData, fields)
    );
    request.setPartialUpdateFieldsList(fields);
    const response = await amosClient.UserControllerPromiseClient.partialUpdate(
      request,
      {}
    );
    const userUpdated = response.toObject();
    return await User.update({
      data: userUpdated,
    });
  },
  async updateUserSignature(_, { usermanagementUuid, signature }) {
    // INFO - NL - 13/02/2024 - Special call (not partialUpdate) as external users can update their signature
    // so this action is allowed in the back for them
    await User.update({
      where: (user) => user.usermanagementUuid === usermanagementUuid,
      data: { signature: signature },
    });
    const request = socioGrpcClient.javascriptToRequest(
      amosClient.UserUpdateUserSignatureRequest,
      { usermanagementUuid, signature },
      []
    );
    await amosClient.UserControllerPromiseClient.updateUserSignature(
      request,
      {}
    );
  },

  async searchUser(context, text) {
    const metadata = {
      filters: JSON.stringify({ search: text }),
    };
    const request = new userClient.UserListRequest();
    const response = await userClient.UserControllerPromiseClient.list(
      request,
      metadata
    );
    const results = response.toObject().resultsList;
    const data = await User.insertOrUpdate({ data: results });
    return data.users ?? [];
  },

  async retrieveByUuid(context, uuid, insertUser = true) {
    const retrieveUserInService = async (uuid, service) => {
      // uuid -> string
      // service -> string (amos_back, user_management)
      const request = new socioGrpcClient[service].user.UserRetrieveRequest();
      request.setUuid(uuid);
      const response = await socioGrpcClient[
        service
      ].user.UserControllerPromiseClient.retrieve(request, {});
      return response.toObject();
    };

    let user;
    try {
      user = await retrieveUserInService(uuid, "amos_back");
      if (!user) {
        user = await retrieveUserInService(uuid, "user_management");
      }
    } catch (err) {
      console.log("error", err);
    }
    if (insertUser) {
      return await User.insert({
        data: { ...user },
      });
    } else {
      return user;
    }
  },

  async fetchManyWithMetadata(
    context,
    { metadata, metadataAmos },
    insertUser = true
  ) {
    const listUsersInService = async (metadata, service) => {
      // value -> field of the model
      // service -> string (amos_back, user_management)
      const request = new socioGrpcClient[service].user.UserListRequest();
      const response = await socioGrpcClient[
        service
      ].user.UserControllerPromiseClient.list(request, metadata);
      return response.toObject().resultsList;
    };

    let users;
    try {
      users = await listUsersInService(metadataAmos || metadata, "amos_back");
      if (!users.length) {
        users = await listUsersInService(metadata, "user_management");
      } else {
        users = users.map((user) => {
          return {
            ...user,
            uuid: user.usermanagementUuid,
          };
        });
      }
    } catch (err) {
      console.log("error", err);
    }
    if (insertUser) {
      return await User.insertOrUpdate({
        data: users,
      });
    } else {
      return users;
    }
  },
  async getUsersFromUuids(context, uuids) {
    const request = new socioGrpcClient.amos_back.user.UserListRequest();
    const metadata = {
      filters: JSON.stringify({
        usermanagement_uuid__in: uuids.filter((uuid) => !!uuid).join(","),
      }),
    };
    const response =
      await socioGrpcClient.amos_back.user.UserControllerPromiseClient.list(
        request,
        metadata
      );
    const { resultsList } = response.toObject();
    await User.insertOrUpdate({
      data: resultsList,
    });
    return resultsList;
  },
  async proVersionDemand(_, requestData) {
    const request = socioGrpcClient.javascriptToRequest(
      amosClient.UserProVersionDemandRequest,
      requestData,
      []
    );
    await amosClient.UserControllerPromiseClient.proVersionDemand(request, {});
  },
};

const mutations = {
  SET_USER_COMPANY: (state, userCompany) => {
    state.userCompany = userCompany;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
