import { Action, Periodicity } from "@/models/";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { buildActionRequest } from "@/utils/action";
import requestFactory from "../factory";

const {
  amos_back: { observation: observationApi },
} = socioGrpcClient;

const client = observationApi.ActionControllerPromiseClient;

const state = {
  actionsCount: 0,
  filterIds: [],
};

const getters = {
  getActionsCount: (state) => state.actionsCount,
  getActions: () => {
    return Action.query().orderBy("createdAt", "desc").orderBy("date").get();
  },
  observationsActionsCount: () => (observationUuids) => {
    return Action.query()
      .withAll()
      .where((action) => {
        return action.observationsList.some((observation) => {
          return observationUuids.includes(observation);
        });
      })
      .count();
  },
  getBudgetedActions: (state) => {
    return Action.query()
      .has("expensesOverTimeList", ">", 0)
      .whereIdIn(state.filterIds)
      .withAllRecursive()
      .get();
  },
};
const actions = {
  async updateAction(_, payload) {
    const request = buildActionRequest(payload);

    const response = await client.update(request, {});

    const formatedResponse = response.toObject();
    await Action.update({
      data: {
        ...formatedResponse,
        periodicity: formatedResponse.periodicity?.uuid,
      },
    });
  },

  async archiveAction({ commit }, actionUuid) {
    await Action.delete(actionUuid);
    commit("SET_ACTIONS_COUNT", getters.getActionsCount - 1);
    const request = new observationApi.ActionSetArchiveValueRequest();
    request.setUuid(actionUuid);
    request.setIsArchived(true);
    await client.setArchiveValue(request, {});
  },

  async destroyAction({ commit, getters }, actionUuid) {
    // INFO - NL - 30/06/2022 - Delete the action if no scenario archived (sent in report), otherwise archived it
    if (!Action.query().find(actionUuid)) return;
    await Action.delete(actionUuid);
    const request = new observationApi.ActionDestroyRequest();
    request.setUuid(actionUuid);
    await client.destroy(request, {});
    commit("SET_ACTIONS_COUNT", getters.getActionsCount - 1);
  },

  async retrievePeriodicity(_, uuid) {
    const request = new observationApi.PeriodicityRetrieveRequest();
    request.setUuid(uuid);
    const response =
      await observationApi.PeriodicityControllerPromiseClient.retrieve(
        request,
        {}
      );
    await Periodicity.insertOrUpdate({ data: response.toObject() });
  },

  exportActions: requestFactory.actions.exportFactory(
    client,
    observationApi.ExportRequest,
    "action"
  ),

  exportActionsArbitration: requestFactory.actions.exportFactory(
    client,
    observationApi.ExportArbitrationRequest,
    "arbitration",
    "exportArbitration"
  ),
};
const mutations = {
  SET_ACTIONS_COUNT: (state, count) => (state.actionsCount = count),
  SET_FILTER_IDS: (state, ids) => {
    state.filterIds = ids;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
